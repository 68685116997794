import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ErrorPage from "../pages/error";

import "../styles/theme.scss";
import LayoutComponent from "../components/Layout";
import Login from "../pages/login";
import Logout from "../pages/logout";
import Register from "../pages/register";
import { logoutUser } from "../actions/user";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  if (!Login.isAuthenticated(localStorage.getItem("id_token"))) {
    dispatch(logoutUser());
    return <Redirect to="/login" />;
  } else {
    return (
      // eslint-disable-line
      <Route {...rest} render={(props) => React.createElement(component, props)} />
    );
  }
};

const LogoutRoute = ({ dispatch, component, ...rest }) => {
  dispatch(logoutUser());
  localStorage.removeItem("id_token");
  document.cookie = "id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  return <Redirect to="/login" />;
};

const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />;

class App extends React.PureComponent {
  render() {
    return (
      <div>
        <ToastContainer autoClose={5000} hideProgressBar closeButton={<CloseButton />} />
        <HashRouter>
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/app/main" />} />
            <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
            <PrivateRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent} />

            <PrivateRoute path="/app/firmware" dispatch={this.props.dispatch} component={LayoutComponent} />

            <PrivateRoute path="/app/firmware-nipper" dispatch={this.props.dispatch} component={LayoutComponent} />
            <Route
              path="/documentation"
              exact
              render={() => <Redirect to="/documentation/getting-started/overview" />}
            />
            {/* <Route path="/documentation" component={DocumentationLayoutComponent}/> */}
            <Route path="/register" exact component={Register} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/login" exact component={Login} />
            <Route path="/error" exact component={ErrorPage} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
