import {
  FETCH_DEVICES_REQUEST,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  POST_FIRMWARE_FAILURE,
  POST_FIRMWARE_SUCCESS,
  POST_FIRMWARE_REQUEST,
  FETCH_FIRMWARE_V_SUCCESS,
  FETCH_FIRMWARE_UPDATE_REQUEST,
  FETCH_FIRMWARE_UPDATE_SUCCESS,
  FETCH_FIRMWARE_UPDATE_FAILURE
} from "../actions/devices";

export default function devices(
  state = {
    isFetching: false,
    isPosting: false,
    success: false,
    firmwareUpdateError: null
  },
  action
) {
  switch (action.type) {
    case FETCH_FIRMWARE_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isPosting: true
      });
    case FETCH_FIRMWARE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isPosting: false,
        success: true,
        firmwareUpdateError: null
      });
    case FETCH_FIRMWARE_UPDATE_FAILURE:
      console.log(action);
      return Object.assign({}, state, {
        isPosting: false,
        success: false,
        firmwareUpdateError: action.firmwareUpdateError
      });
    case FETCH_DEVICES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        devices: action.devices
      });
    case FETCH_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: "Something wrong happened. Please come back later"
      });
    case POST_FIRMWARE_REQUEST:
      return Object.assign({}, state, {
        isPosting: true
      });
    case POST_FIRMWARE_SUCCESS:
      return Object.assign({}, state, {
        isPosting: false,
        success: true,
        fileUrl: action.fileUrl
      });
    case POST_FIRMWARE_FAILURE:
      return Object.assign({}, state, {
        isPosting: false,
        success: false,
        message: action.postingError.message
      });
    case FETCH_FIRMWARE_V_SUCCESS:
      return Object.assign({}, state, {
        firmwareVersion: action.firmwareVersion
      });
    default:
      return state;
  }
}
