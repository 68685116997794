export const environment = process.env.REACT_APP_ENVIRONMENT;

export const appConfig = {
  "lifeguard-prod": {
    isBackend: true,
    backendURL: "https://lg.dwprod.co/",
    product: "Lifeguard",
    serialPlaceHolder: "Search: dwlg-xxxxxxxx, dwlg-xxxxxxxx, ...",
  },
  "lifeguard-uat": {
    isBackend: true,
    backendURL: "https://lg.uat.dwprod.co/",
    product: "Lifeguard-uat",
    serialPlaceHolder: "Search: dlgt-xxxxxxxx, dlgt-xxxxxxxx, ...",
  },
  "henden-prod": {
    isBackend: true,
    backendURL: "https://hwqh.henden.co/",
    product: "Henden",
    serialPlaceHolder: "Search: hwqh-xxxxxxxx, hwqh-xxxxxxxx, ...",
  },
};
