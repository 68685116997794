import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

import Widget from "../../components/Widget";

import s from "./Dashboard.module.scss";
import DeviceTable from "../../components/TableCells/DeviceTable";
import { fetchDevices, fetchSearchDevice, fetchFirmwareVersion, fetchSearchDevices } from "../../actions/devices";
import { appConfig, environment } from "../../config";

class Dashboard extends Component {
  /* eslint-disable */
  static propTypes = {
    devices: PropTypes.any,
    isFetching: PropTypes.bool,
    firmwareVersion: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    devices: [],
    firmwareVersion: "...",
    isFetching: false,
  };

  state = {
    isDropdownOpened: false,
  };

  componentDidMount() {
    this.props.dispatch(fetchDevices());
    this.props.dispatch(fetchFirmwareVersion(appConfig[environment].product));
  }

  formatDate = (str) => {
    return str.replace(/,.*$/, "");
  };

  keyPress(e) {
    if (e.keyCode === 13) {
      console.log("value", e.target.value);
      this.props.dispatch(fetchSearchDevices(e.target.value));
    }
  }

  render() {
    return (
      <div className={s.root}>
        <Breadcrumb>
          {/* <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem> */}
          <BreadcrumbItem active>Dashboard</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Dashboard</h1>
        <h3 className="mb-sm"> {`Current firmware version: ${this.props.firmwareVersion}`}</h3>
        <Row>
          <Col sm={12} md={12}>
            <Widget
              title={
                <div>
                  <h5 className="mt-0">
                    <i className="fa fa-user mr-xs opacity-70" /> Devices
                  </h5>
                </div>
              }
            >
              <div className="mt-n-xs">
                <input
                  type="search"
                  placeholder={appConfig[environment].serialPlaceHolder}
                  className="form-control input-sm"
                  onKeyDown={(e) => this.keyPress(e)}
                />
              </div>
              <DeviceTable></DeviceTable>
            </Widget>
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.posts.isFetching,
    devices: state.devices.devices,
    firmwareVersion: state.devices.firmwareVersion,
  };
}

export default connect(mapStateToProps)(Dashboard);
