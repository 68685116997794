import React, { Component } from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import Widget from "../../components/Widget/Widget";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import s from "./Profile.module.scss";
import NipperUpload from "./upload/NipperUpload";
import { fetchFirmwareVersion } from "../../actions/devices";
import NipperDeviceList from "./NipperDeviceList";

class NipperFirmwarePage extends Component {
  /* eslint-disable */
  static propTypes = {
    dispatch: PropTypes.func,
    firmwareVersion: PropTypes.string
  };
  /* eslint-enable */

  static defaultProps = {
    firmwareVersion: "..."
  };

  state = {
    files: [],
    uploading: false,
    uploadProgress: {},
    successfullUploaded: false
  };
  constructor(props) {
    super(props);
    this.props.dispatch(fetchFirmwareVersion("Nipper"));
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", "http://localhost:8000/upload");
      req.send(formData);
    });
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  render() {
    return (
      <div className={s.root}>
        <Breadcrumb>
          {/* <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem> */}
          <BreadcrumbItem active>Nipper Firmware Page</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Firmware</h1>
        <h3>{`current version: ${this.props.firmwareVersion}`}</h3>
        <p></p>
        <Row>
          <Col sm={8}>
            <Widget
              title={
                <h5>
                  Upload New Firmware <span className="fw-semi-bold">Form</span>
                </h5>
              }
            >
              <NipperUpload></NipperUpload>
            </Widget>
          </Col>
          <Col sm={8}>
            <Widget
              title={<h5>Select devices to updated to the latest version</h5>}
            >
              <NipperDeviceList></NipperDeviceList>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    firmwareVersion: state.devices.firmwareVersion
  };
}

export default connect(mapStateToProps)(NipperFirmwarePage);
