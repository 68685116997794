import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";

import { connect } from "react-redux";
/* import Icon from "../../../components/Icon/Icon"; */
import { Label, Input } from "reactstrap";
import * as semver from "semver";
import PropTypes from "prop-types";
import { postFirmware } from "../../../actions/devices";
import { appConfig, environment } from "../../../config";

class Upload extends Component {
  /* eslint-disable */
  /* jshint ignore:start */
  static propTypes = {
    isPosting: PropTypes.bool,
    fileUrl: PropTypes.string,
    dispatch: PropTypes.func,
  };
  /* eslint-enable */

  static defaultProps = {
    isPosting: false,
    fileUrl: null,
  };

  state = {
    file: null,
    version: "",
    versionError: false,
  };
  /* jshint ignore:end */
  constructor(props) {
    super(props);

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  onFilesAdded(file) {
    console.log(file.name, "file name");
    this.setState((prevState) => ({
      file: file,
    }));
  }

  uploadFiles() {
    if (!semver.valid(this.state.version)) {
      console.log("not valid");

      this.setState((prevState) => ({
        versionError: true,
      }));
      return;
    } else {
      const k = semver.clean(this.state.version);

      const arr = k.split(".");

      for (let i = 0; i < arr.length; i++) {
        const element = parseInt(arr[i]);
        if (element > 255) {
          this.setState((prevState) => ({
            versionError: true,
          }));
          return;
        }
      }

      this.setState((prevState) => ({
        versionError: false,
      }));
    }

    this.props.dispatch(postFirmware(this.state.file, this.state.version, appConfig[environment].product));
  }

  renderActions() {
    if (this.props.success && this.state.file) {
      return (
        <div>
          <Label>{this.props.fileUrl}</Label>
          <button onClick={() => this.setState({ file: null })}>Clear</button>
        </div>
      );
    } else {
      return (
        <button disabled={this.state.file == null || this.props.isPosting} onClick={this.uploadFiles}>
          Upload
        </button>
      );
    }
  }

  keyPress = (e) => {
    this.setState({ version: e.target.value });
  };

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          <div>
            <Dropzone onFilesAdded={this.onFilesAdded} disabled={this.props.isPosting || this.props.success} />
          </div>
          <div className="Files">
            {this.state.file && (
              <div key={this.state.file.name} className="Row">
                <span className="Filename">{this.state.file.name}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          <Label for="input-name">Verision</Label>
          <Input bsSize="lg" type="text" name="name" id="input-name" onChange={(e) => this.keyPress(e)} />

          <Label>version number has to be in a "xx.xx.xx" format</Label>

          {this.state.versionError && (
            <div>
              <Label style={{ color: "red" }}>The version number is not valid</Label>
            </div>
          )}
        </div>

        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isPosting: state.devices.isPosting,
    success: state.devices.success,
    fileUrl: state.devices.fileUrl,
  };
}

export default connect(mapStateToProps)(Upload);
