import { logoutUser } from "./user";
import { appConfig, environment } from "../config";

const FormData = require("form-data");

export const FETCH_DEVICES_REQUEST = "FETCH_DEVICES_REQUEST";
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
export const FETCH_DEVICES_FAILURE = "FETCH_DEVICES_FAILURE";

export const POST_FIRMWARE_REQUEST = "POST_FIRMWARE_REQUEST";
export const POST_FIRMWARE_SUCCESS = "POST_FIRMWARE_SUCCESS";
export const POST_FIRMWARE_FAILURE = "POST_FIRMWARE_FAILURE";

export const FETCH_FIRMWARE_V_SUCCESS = "FETCH_FIRMWARE_V_SUCCESS";

export const FETCH_FIRMWARE_UPDATE_REQUEST = "FETCH_FIRMWARE_UPDATE_REQUEST";
export const FETCH_FIRMWARE_UPDATE_SUCCESS = "ETCH_FIRMWARE_UPDATE_SUCCESS";
export const FETCH_FIRMWARE_UPDATE_FAILURE = "ETCH_FIRMWARE_UPDATE_FAILURE";

function fetchDeviceFirmware(firmwareVersion) {
  return {
    type: FETCH_FIRMWARE_V_SUCCESS,
    firmwareVersion,
  };
}

function requestFetchDevices() {
  return {
    type: FETCH_DEVICES_REQUEST,
    isFetching: true,
  };
}

function fetchDevicesSuccess(devices) {
  return {
    type: FETCH_DEVICES_SUCCESS,
    isFetching: false,
    devices,
  };
}

function fetchDevicesError(message) {
  return {
    type: FETCH_DEVICES_FAILURE,
    isFetching: false,
    message,
  };
}

function postFirmwareRequest() {
  return {
    type: POST_FIRMWARE_REQUEST,
    isPosting: true,
    success: true,
  };
}

function postFirmwareSuccess(fileUrl) {
  return {
    type: POST_FIRMWARE_SUCCESS,
    isPosting: false,
    success: true,
    fileUrl,
  };
}

function postFirmwareError(error) {
  return {
    type: POST_FIRMWARE_FAILURE,
    isPosting: false,
    success: false,
    postingError: error,
  };
}

function updateFirmwareRequest() {
  return {
    type: FETCH_FIRMWARE_UPDATE_REQUEST,
    isPosting: true,
    success: true,
  };
}

function updateFirmwareSuccess(firmwareVerison) {
  return {
    type: FETCH_FIRMWARE_UPDATE_SUCCESS,
    isPosting: false,
    success: true,
    firmware: firmwareVerison,
  };
}

function updateFirmwareError(error) {
  return {
    type: FETCH_FIRMWARE_UPDATE_FAILURE,
    isPosting: false,
    success: false,
    firmwareUpdateError: error.message,
  };
}

export function fetchSearchDevice(serialNumber) {
  const configHeader = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
  };
  console.log("Env : ", environment);
  return (dispatch) => {
    dispatch(requestFetchDevices());

    return fetch(appConfig[environment].backendURL + "admin/search?serialNumber=" + serialNumber, configHeader)
      .then((response) => response.json())
      .then(({ devices, count }) => {
        // Dispatch the success action
        dispatch(fetchDevicesSuccess(devices));
        return Promise.resolve(devices);
      })
      .catch((err) => {
        dispatch(fetchDevicesError(err));
        return Promise.reject(err);
      });
  };
}

export function fetchSearchDevices(sn) {
  const serialNumbers = sn.replaceAll(/\s/g, "").split(",");
  const configHeader = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify({ serialNumbers }),
  };
  console.log("Env : ", environment);
  return (dispatch) => {
    dispatch(requestFetchDevices());

    return fetch(appConfig[environment].backendURL + "admin/search-devices", configHeader)
      .then((response) => response.json())
      .then(({ devices }) => {
        // Dispatch the success action
        dispatch(fetchDevicesSuccess(devices));
        return Promise.resolve(devices);
      })
      .catch((err) => {
        dispatch(fetchDevicesError(err));
        return Promise.reject(err);
      });
  };
}

export function fetchFirmwareVersion(product) {
  const configHeader = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
  };
  console.log("Env : ", environment);
  return (dispatch) => {
    dispatch(requestFetchDevices());

    return fetch(appConfig[environment].backendURL + "admin/firmware-version?product=" + product, configHeader)
      .then((response) => response.json())
      .then(({ firmware }) => {
        // Dispatch the success action
        dispatch(fetchDeviceFirmware(firmware));
        return Promise.resolve(firmware);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function fetchDevices() {
  // console.log(localStorage.getItem("id_token"));
  const configHeader = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
  };
  console.log("Env : ", environment, localStorage.getItem("id_token"));
  return (dispatch) => {
    dispatch(requestFetchDevices());

    return fetch(appConfig[environment].backendURL + "admin/all?count=50&start=0", configHeader)
      .then((response) => response.json())
      .then(({ devices, count }) => {
        // Dispatch the success action
        console.log(devices);
        if (!devices) throw new Error("No devices");
        let arr = devices.sort(function(x, y) {
          return x.isConnected === y.isConnected ? 0 : x.isConnected ? -1 : 1;
        });

        dispatch(fetchDevicesSuccess(arr));
        return Promise.resolve(arr);
      })
      .catch((err) => {
        console.log("ERROR", err);
        dispatch(logoutUser());
        dispatch(fetchDevicesError(err));
        return Promise.reject(err);
      });
  };
}

export function updateDeviceFirmware(version, serialNumbers, product) {
  console.log("Env : ", environment);
  const configHeader = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify({ firmwareVersion: version, serialNumbers, product }),
  };
  return (dispatch) => {
    dispatch(updateFirmwareRequest());

    return fetch(appConfig[environment].backendURL + "admin/update-devices", configHeader)
      .then((response) => response.json())
      .then(({ firmwareVersion, response }) => {
        // Dispatch the success action
        dispatch(updateFirmwareSuccess(firmwareVersion));
        return Promise.resolve(firmwareVersion);
      })
      .catch((err) => {
        dispatch(updateFirmwareError(err));
        return Promise.reject(err);
      });
  };
}

export function postFirmware(data, version, product) {
  const form = new FormData();
  form.append("File", data);
  form.append("file", data);
  console.log(product);
  console.log("Env : ", environment);
  let url = `${appConfig[environment].backendURL}admin/upload-firmware?version=${version}&product=${product}`;

  const configHeader = {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
    body: form,
  };

  return (dispatch) => {
    dispatch(postFirmwareRequest());

    return fetch(url, configHeader)
      .then((response) => response.json())
      .then((res) => {
        // Dispatch the success action
        console.log(res);
        dispatch(postFirmwareSuccess(res.fileUrl));
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        dispatch(postFirmwareError(err));
        return Promise.reject(err);
      });
  };
}
