import { appConfig, environment } from "../config";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

export function receiveLogin(token) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: token,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem("id_token");
    document.cookie = "id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      email: creds.login,
      password: creds.password,
    }),
  };
  console.log("Env : ", appConfig[environment], environment);
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    return fetch(appConfig[environment].backendURL + "user/login", config)
      .then((response) => response.json())
      .then(({ token, user }) => {
        if (!token) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError("Login Error"));
          return Promise.reject("Did not login");
        }
        // in posts create new action and check http status, if malign logout
        // If login was successful, set the token in local storage
        localStorage.setItem("id_token", token);
        // Dispatch the success action
        dispatch(receiveLogin(token));
        return Promise.resolve(token);
      })
      .catch((err) => {
        dispatch(loginError("Login Error"));
      });
  };
}
