import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { Table, Input, Label } from "reactstrap";
import { updateDeviceFirmware } from "../../actions/devices";

class NipperDeviceList extends React.Component {
  static propTypes = {
    devices: PropTypes.array,
    firmwareVersion: PropTypes.string,
    success: PropTypes.bool,
  };
  static defaultProps = {
    devices: [],
    isFetching: false,
    firmwareVersion: null,
    success: false,
  };

  state = {
    selected: {},
    selectAll: 0,
    cleared: false,
  };

  toggleRow(serialNumber) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[serialNumber] = !this.state.selected[serialNumber];
    this.setState({
      selected: newSelected,
      selectAll: 2,
    });
  }

  selectedSerialNumbers() {
    const keys = Object.keys(this.state.selected);
    if (keys.length === 0) {
      return [];
    }
    const val = keys.map((x) => {
      if (this.state.selected[x] === true) {
        console.log(x);
        return x;
      } else {
        return null;
      }
    });
    const k = val.filter((x) => x != null);
    console.log(k);
    return k;
  }

  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.props.devices.forEach((x) => {
        newSelected[x.serialNumber] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  renderActions() {
    if (this.props.success || this.state.cleared) {
      return (
        <div>
          <Label>{"Updating devices. This could take some time"}</Label>
          <button onClick={() => this.setState({ selected: {}, cleared: true })}>Clear</button>
        </div>
      );
    } else {
      return (
        <button
          disabled={this.selectedSerialNumbers().length < 1 || this.props.isPosting || !this.props.firmwareVersion}
          onClick={() => this.updateFirmware(this.props.firmwareVersion)}
        >
          {`Update to ${this.props.firmwareVersion}`}
        </button>
      );
    }
  }

  updateFirmware(firmware) {
    if (!firmware) {
      alert("firmware version cannot be found");
      return;
    }
    console.log(firmware);
    this.props.dispatch(updateDeviceFirmware(firmware, this.selectedSerialNumbers(), "Nipper"));
    return false;
  }

  render() {
    return (
      <div>
        <button onClick={() => this.toggleSelectAll()}>{`select all`}</button>
        <Table>
          <thead>
            <tr>
              <th>Select</th>
              <th>ID</th>
              <th>Serial #</th>
              <th>Firmware #</th>
              <th>Nipper Firmware #</th>
              <th>Connected</th>
            </tr>
          </thead>
          <tbody>
            {this.props.devices.map((device) => {
              return (
                <tr key={device.id}>
                  <td>
                    {" "}
                    <Input
                      type="checkbox"
                      className="checkbox"
                      checked={this.state.selected[device.serialNumber] === true}
                      onChange={() => this.toggleRow(device.serialNumber)}
                    />{" "}
                  </td>
                  <td>{device.id}</td>
                  <td>{device.serialNumber}</td>
                  <td>{device.version}</td>
                  <td>{device.nipperVersion}</td>

                  {device.isConnected && (
                    <td>
                      <span className="py-0 px-1 bg-success rounded text-white">online</span>
                    </td>
                  )}
                  {!device.isConnected && (
                    <td>
                      <span className="py-0 px-1 bg-warning rounded text-white">offline</span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <div>
            {this.props.firmwareUpdateError && <span style={{ color: "red" }}>{this.props.firmwareUpdateError}</span>}
          </div>

          <div className="Actions">{this.renderActions()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    init: state.runtime.initialNow,
    devices: state.devices.devices,
    success: state.devices.success,
    firmwareVersion: state.devices.firmwareVersion,
    firmwareUpdateError: state.devices.firmwareUpdateError,
  };
}
export default connect(mapStateToProps)(NipperDeviceList);
