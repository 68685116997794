import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/user";
class Logout extends React.Component {
  componentWillMount() {
    this.props.dispatch(logoutUser());
    logoutUser();
  }

  render() {
    return <Redirect to="/" />;
  }
}

export default withRouter(connect()(Logout));
