import components from "./components.svg";
import dashboard from "./dashboard.svg";
import notifications from "./notifications.svg";
import typography from "./typography.svg";
import tables from "./tables.svg";
import logo from "./logo.png";
import settings from "./settings.svg";
import notification from "./notification.svg";
import mail from "./mail.svg";
import bcloud from "./baseline-cloud_upload-24px.svg";

export default {
  components,
  dashboard,
  notifications,
  typography,
  tables,
  logo,
  settings,
  notification,
  mail,
  bcloud
};
